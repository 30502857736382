<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <student-datatable/>
      </div>
    </div>
  </div>
</template>

<script>
  import StudentDatatable from "./datatables/StudentDatatable";

  export default {
    name: "ArchiveIndexPage",
    components: {StudentDatatable}
  }
</script>

<style scoped lang="scss">

</style>
