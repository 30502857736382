<template>
  <octo-table
    show-filters
    :enable-export="isTeachingManager"
    :action="endpoints.DATATABLES.students"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:student="data">
      <div class="row">
        <div class="col-12">
          {{ data.row.surname }} {{ data.row.name }}
        </div>
        <div class="col-12 small">
          <label-theme-component>{{data.row.course_title}}</label-theme-component>
        </div>
      </div>
    </template>

    <template v-slot:classroom="data">
      <div class="small">
        {{ data.row.classroom_code }}
      </div>
    </template>

    <template v-slot:type="data">
      <badge v-if="data.row.course_type" :type="data.row.course_type | courseTypeColor" class="text-uppercase">
        {{ $t('common.' + data.row.course_type) }}
      </badge>
    </template>

    <template v-slot:status="data">
      <badge v-if="data.row.status" :type="data.row.status | studentStatusColor" class="text-uppercase">
        {{ $t('didactics.student_status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:date="data">
      <div class="small">
        {{ data.row.date_in | date }} <span v-if="data.row.date_out">- {{ data.row.date_out | date }}</span>
      </div>
    </template>

    <template v-slot:deal_status="data">
      <badge
        v-if="data.row.deal_status"
        :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.deal_status)}"
        class="text-uppercase"
      >
        {{ $t('deal.status.' + data.row.deal_status) }}
      </badge>
      <div v-if="!data.row.deal_status" class="text-uppercase small">
        {{ $t('common.missing_deal') }}
      </div>
    </template>

    <template v-slot:accounting_status="data">
      <badge
        v-if="data.row.accounting_status"
        :type="dealAccountingStatus[data.row.accounting_status].color"
        class="text-uppercase"
      >
        {{ $t('deal.accounting_statuses.' + data.row.accounting_status) }}
      </badge>
    </template>

    <template v-slot:sales="data">
      <div class="small">
        {{ data.row.sale_lastname }}  {{ data.row.sale_firstname }}
      </div>
    </template>

    <template v-slot:actions="data">
          <base-button
            class="mx-1"
            @click="redirectStudent(data.row)"
            size="sm"
            link
            icon
          >
            <octo-icon icon="right-arrow"/>
          </base-button>
    </template>

    <template slot="custom-filters">
      <div class="col-9">
        <base-input :label="$t('fields.courses')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseIds"
          >
            <el-option
              v-for="course in courses"
              class="select-default text-uppercase"
              :value="course.id"
              :label="course.title"
              :key="course.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('datatable.type')">
          <el-select
            clearable
            multiple
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseTypes"
          >
            <el-option
              v-for="courseType in courseTypes"
              class="select-default text-uppercase"
              :value="courseType"
              :label="$t('common.' + courseType)"
              :key="courseType"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('common.status')">
          <el-select
            multiple
            clearable
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.statuses"
          >
            <el-option
              v-for="status in statuses"
              class="select-default text-uppercase"
              :value="status.value"
              :label="$t('didactics.student_status.' + status.value)"
              :key="status.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4" v-if="isTeachingManager">
        <base-input :label="$t('datatable.sales')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.salesIds"
          >
            <el-option
              v-for="user in sales"
              class="select-default text-uppercase"
              :value="user.id"
              :key="user.id"
              :label="`${$options.filters.optional(user, 'firstname')} ` +
                      `${$options.filters.optional(user, 'lastname')}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-4"  v-if="isTeachingManager">
        <base-input :label="$t('fields.tutors')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.tutorIds"
          >
            <el-option
              class="select-default text-uppercase"
              v-for="tutor in tutors"
              :value="tutor.id"
              :label="`${$options.filters.optional(tutor, 'firstname')}
                       ${$options.filters.optional(tutor, 'lastname')}`"
              :key="`tutor-${tutor.id}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>


      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_in')">
          <el-date-picker
            v-model="filters.dateInRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.date_out')">
          <el-date-picker
            v-model="filters.dateOutRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import {mapGetters} from "vuex";
import {Select, Option, DatePicker} from "element-ui";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import StudentStatuses from "@/pages/Students/resources/studentStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import {permissionService} from "@/util/permission-service";
import DealWeeklyFrequencies from "@/pages/Deals/resources/dealWeeklyFrequencies";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

  export default {
    name: "StudentDatatable",
    components: {
      LabelThemeComponent,
      Badge,
      OctoIcon,
      OctoTable,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
    },
    data() {
      return {
        name: 'StudentDatatable',
        endpoints: endpoints,
        filters: {},
        fields: [
          {prop: 'student', label: 'student', minWidth: 300, slot: true, align: 'left'},
          {prop: 'date', label: 'date', slot: true, align: 'center', width: 200},
          {prop: 'classroom', label: 'classroom', slot: true, minWidth: 200, align: 'center'},
          {prop: 'type', label: 'type', slot: true, width: 150, align: 'center'},
          {prop: 'status', label: 'status', width: 200, slot: true, align: 'center'},
          {prop: 'deal_status', label: 'deal_status', width: 200, slot: true, align: 'center'},
          {prop: 'accounting_status', label: 'accounting_status', width: 200, slot: true, align: 'center'},
          {prop: 'sales', label: 'sales', slot: true, width: 200},
          {prop: 'actions', label: 'actions', width: 100, slot: true, align: 'center', fixed: 'right'},
        ],
        courseTypes: [classroomTypes.course.value, classroomTypes.workshop.value, classroomTypes.certificate.value],
        dealWeeklyFrequencies: DealWeeklyFrequencies,
        dealAccountingStatus: DealAccountingStatus,
        schedulerService: schedulerService,
      }
    },
    computed: {
      ...mapGetters({
        courses: 'common/allCourses',
        getFilters: 'common/datatableFilters',
        locations: 'common/locations',
        tutors: 'common/tutors',
        sales: 'common/sales',
      }),

      isTeachingManager() {
        return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
      },
      pickerOptions() {
        return {firstDayOfWeek: 1};
      },
      statuses() {

        const statuses = [
          StudentStatuses.attending,
          StudentStatuses.unjustified_absence,
          StudentStatuses.request_stand_by,
          StudentStatuses.request_retired,
          StudentStatuses.retired,
          StudentStatuses.to_block_for_non_payment,
          StudentStatuses.to_unblock,
          StudentStatuses.completed,
          StudentStatuses.deleted,
          StudentStatuses.to_block_for_withdrawal,
          StudentStatuses.blocked_for_withdrawal,
          StudentStatuses.stand_by,
        ];

        if (this.isTeachingManager) {
          statuses.unshift(
            StudentStatuses.pending,
            StudentStatuses.joined,
          );
        }

        return statuses;
      }
    },
    beforeMount() {
      this.filters = this.getFilters(this.name);
    },
    methods: {
      redirectStudent(student) {
        if (student.status === StudentStatuses.pending.value || student.status === StudentStatuses.joined.value) {
          this.$router.push({name: 'students.waiting.show', params: {id: student.id}})
        } else {
          this.$router.push({name: 'students.archive.show', params: {id: student.id}})
        }
      },

      getMainClassroomCode(student) {

        return '';
        const mainClassroom = this.$_.find(student.classrooms, classroom => classroom.student_classroom === 'main');

        return mainClassroom?.code || student?.classrooms?.[0]?.code;
      },

      getLocationAlias(locationId) {
        return this.$_.find(this.locations, location => location.id === locationId)?.alias;
      }
    }
  }
</script>

<style scoped>

</style>
